import React from "react";
import {
  InputText,
  ColorPicker,
  // InputTime,
  InputSelect,
  Button,
  InputDateRange,
  InputNumber,
} from "@bluesilodev/timhutcomponents";
import { Formik } from "formik";
import { useGetAllLocation } from "services/employeeApi";

import EmployeeNeeded from "./employeeNeeded";

import InputWeekly from "./InputWeekly";
import InputTime from "components/allInput/inputTimeShift";

import { generateTime } from "utils/common/generatedTime";
import { useSelector } from "react-redux";

function FormShift({
  initialValue,
  validationSchema,
  handleSubmit,
  nameButton,
  iconButton,
  shiftNameAssign,
  setValueShiftName,
}) {
  const { currentRole } = useSelector((state) => state.loginSlice);

  // GET ALL LOCATION
  const { data: locationData, isLoading: loadingLocation } =
    useGetAllLocation();

  if (loadingLocation) {
    return <div>Loading...</div>;
  }

  // SELECT LOCATION
  const selectLocation = locationData.data.map((item) => {
    return {
      label: item.locationName,
      value: item.uId,
    };
  });

  return (
    <Formik
      initialValues={initialValue}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      className="w-full"
    >
      {({
        values,
        handleChange,
        setFieldValue,
        handleSubmit,
        handleBlur,
        errors,
        touched,
      }) => {
        //======== SHIFT TIME
        const resulDif = generateTime(values.startTime, values.endTime);

        //========= BREAK TIME
        const resultBreak = generateTime(
          values.startBreakTime,
          values.endBreakTime
        );

        const handleCheckboxChange = (event, checkedValue) => {
          if (values.repeatBy.repeatType === "Weekly") {
            const { checked } = event.target;
            let currentValues = Array.isArray(values.repeatBy.repeatValue)
              ? values.repeatBy.repeatValue
              : [];

            if (checked) {
              if (!currentValues.includes(checkedValue)) {
                currentValues = [...currentValues, checkedValue];
              }
            } else {
              currentValues = currentValues.filter(
                (val) => val !== checkedValue
              );
            }

            setFieldValue("repeatBy.repeatValue", currentValues);
          }
        };

        return (
          <form onSubmit={handleSubmit} autoComplete="off" className="w-full">
            <div className="flex gap-3  w-full">
              <div className="w-[83%]">
                <InputText
                  title={"Shift Name"}
                  id="shiftName"
                  value={values.shiftName}
                  placeholder="Enter Shift Name"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  required={true}
                  disabled={currentRole !== "Admin"}
                  error={
                    errors.shiftName && touched.shiftName && errors.shiftName
                  }
                />
              </div>
              <div className="w-[16%]">
                <ColorPicker
                  name="color"
                  value={values.color}
                  setFieldValue={setFieldValue}
                  rightPosition={"right-[2px]"}
                  disabled={currentRole !== "Admin"}
                />
              </div>
            </div>

            <div className="flex gap-3  w-full mt-3 flex-col">
              <h1 className="font-semibold ">Time</h1>
              <div className="flex gap-3 w-full justify-between ">
                <div className="w-full">
                  <InputTime
                    label={"Start Time"}
                    value={values.startTime}
                    disable={currentRole !== "Admin"}
                    error={
                      errors.startTime && touched.startTime && errors.startTime
                    }
                    setFieldValue={(_, val) => setFieldValue("startTime", val)}
                  />
                </div>

                <div className="w-full">
                  <InputTime
                    label={"End Time"}
                    value={values.endTime}
                    disable={currentRole !== "Admin"}
                    error={errors.endTime && touched.endTime && errors.endTime}
                    setFieldValue={(_, val) => setFieldValue("endTime", val)}
                  />
                </div>

                <div className="w-[250px]">
                  <p>Shift Time</p>
                  <p className="font-semibold">
                    {resulDif.hours} Hours {resulDif.minutes} minute
                  </p>
                </div>
              </div>

              <div className="flex gap-3 w-full justify-between">
                <div className="w-full">
                  <InputTime
                    label={"Start Break Time"}
                    value={values.startBreakTime}
                    disable={currentRole !== "Admin"}
                    error={
                      errors.startBreakTime &&
                      touched.startBreakTime &&
                      errors.startBreakTime
                    }
                    setFieldValue={(_, val) =>
                      setFieldValue("startBreakTime", val)
                    }
                  />
                </div>
                <div className="w-full">
                  <InputTime
                    label={"End Break Time"}
                    value={values.endBreakTime}
                    disable={currentRole !== "Admin"}
                    error={
                      errors.endBreakTime &&
                      touched.endBreakTime &&
                      errors.endBreakTime
                    }
                    setFieldValue={(_, val) =>
                      setFieldValue("endBreakTime", val)
                    }
                  />
                </div>
                <div className="w-[250px]">
                  <p>Break Time</p>
                  <p className="font-semibold">
                    {resultBreak.hours} Hours {resultBreak.minutes} minute
                  </p>
                </div>
              </div>
            </div>

            {/* THIS IS REPEATS */}

            <div className="mt-3">
              <h1 className="font-semibold">Repeats</h1>
              <div className="flex gap-3">
                <InputDateRange
                  label={"Date Range"}
                  name={"repeatDate"}
                  value={values.repeatDate}
                  setFieldValue={setFieldValue}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={
                    errors.repeatDate && touched.repeatDate && errors.repeatDate
                  }
                  required={true}
                  disabled={currentRole !== "Admin"}
                />

                <InputSelect
                  disabled={currentRole !== "Admin"}
                  id="repeatBy.repeatType"
                  title={"Repeats"}
                  value={values.repeatBy.repeatType}
                  options={[
                    { label: "Daily", value: "Daily" },
                    { label: "Weekly", value: "Weekly" },
                    { label: "Monthly", value: "Monthly" },
                  ]}
                  onChange={(e) => {
                    const selectedValue = e.target.value;
                    setFieldValue(`repeatBy.repeatType`, selectedValue);

                    // Reset inputRepeatValue based on the new repeatType
                    if (selectedValue !== "Weekly") {
                      setFieldValue("repeatBy.repeatValue", 0);
                    } else {
                      setFieldValue("repeatBy.repeatValue", []);
                    }
                  }}
                  onBlur={handleBlur}
                  error={
                    errors.repeatBy &&
                    errors.repeatBy.repeatType &&
                    touched.repeatBy &&
                    touched.repeatBy.repeatType &&
                    errors.repeatBy.repeatType
                  }
                />
              </div>
            </div>

            <div
              className={`${
                errors.repeatDate &&
                touched.repeatDate &&
                errors.repeatDate &&
                "mt-14"
              }`}
            >
              {values.repeatBy.repeatType === "Weekly" && (
                <div className="">
                  <InputWeekly
                    disabled={currentRole !== "Admin"}
                    values={values}
                    errors={errors}
                    touched={touched}
                    handleCheckboxChange={handleCheckboxChange}
                  />
                </div>
              )}

              {values.repeatBy.repeatType === "Daily" && (
                <div className="w-[49%] mt-5">
                  <InputNumber
                    disable={currentRole !== "Admin"}
                    label={"Every"}
                    unit={"Day(s)"}
                    name={"repeatBy.repeatValue"}
                    onBlur={handleBlur}
                    value={values.repeatBy.repeatValue}
                    error={
                      errors.repeatBy &&
                      errors.repeatBy.repeatValue &&
                      touched.repeatBy &&
                      touched.repeatBy.repeatValue &&
                      errors.repeatBy.repeatValue
                    }
                    setFieldValue={setFieldValue}
                  />
                </div>
              )}

              {values.repeatBy.repeatType === "Monthly" && (
                <div className="w-[49%] mt-5">
                  <InputNumber
                    disable={currentRole !== "Admin"}
                    label={"Every"}
                    unit={"Month(s)"}
                    name={"repeatBy.repeatValue"}
                    value={values.repeatBy.repeatValue}
                    onBlur={handleBlur}
                    error={
                      errors.repeatBy &&
                      errors.repeatBy.repeatValue &&
                      touched.repeatBy &&
                      touched.repeatBy.repeatValue &&
                      errors.repeatBy.repeatValue
                    }
                    setFieldValue={setFieldValue}
                  />
                </div>
              )}
            </div>

            <div className="mt-3 flex flex-col gap-3">
              <h1 className="font-semibold">
                Employee and Job Positions Needed
              </h1>
              <div className="flex flex-col gap-3">
                <div className="w-[90%]">
                  <InputSelect
                    disabled={currentRole !== "Admin"}
                    title={"Locations"}
                    value={values.locationId}
                    onChange={handleChange}
                    name="locationId"
                    // disabled={true}
                    options={selectLocation}
                    onBlur={handleBlur}
                    error={
                      errors.locationId &&
                      touched.locationId &&
                      errors.locationId
                    }
                  />
                </div>

                <EmployeeNeeded locationId={values.locationId} />
              </div>
            </div>

            <div className="w-full mt-5 ">
              {currentRole === "Admin" && (
                <Button
                  type={"submit"}
                  style={"solid"}
                  className={"w-full"}
                  label={
                    <div className="flex gap-2 items-center">
                      {/* <FiFilePlus />
                      <h1>Create New Shift</h1> */}
                      <div>{iconButton}</div>
                      <h1>{nameButton}</h1>
                    </div>
                  }
                />
              )}
            </div>
          </form>
        );
      }}
    </Formik>
  );
}

export default FormShift;

import React, { useEffect, useState } from "react";
import {
  ModalDialog,
  ModalConfirmation,
  ShiftListBox,
} from "@bluesilodev/timhutcomponents";
// import ShiftListBox from "coba/shiftlistbox/ShiftListbox";
import { useSelector, useDispatch } from "react-redux";
import { useQueryClient } from "@tanstack/react-query";
import { setShowRequesReplacement } from "store/requestReplacementSlice";

import { setLocationState } from "store/employeeViewSlice";
import {
  useGetAllShift,
  useDeleteShift,
  useUpdateShift,
} from "services/shiftServices";
import { useGetAllLocation } from "services/employeeApi";
import {
  useGetAllDashboard,
  useDeleteAssignShift,
} from "services/assignShiftService";
import { useReplacementConflick } from "context/ReplacementConflickProvider";
import { useDivRef } from "context/refProvider";
import { useGetAllEmployee } from "services/employeeApi";

import ConflictOneData from "./conflickOneData";
import { alertSuccess, alertError } from "services/alert";

import AddAssignShift from "pages/dashboard/employeeView/assignShift/addAssignShift";
import FindReplacementRequest from "./findReplacementRequest";
import ModalEditShiftDetail from "./allModal/modalEditShiftDetail";
import ModalEditAssignShift from "./allModal/modalEditAssignShift";
// import ShiftListBox from "components/ShiftListBox/ShiftListBox";
import { formatDate } from "utils/common/generateDateRange";

function EmployeeView() {
  // MODAL
  const [showModalAssign, setShowModalAssign] = useState(false);
  const [showModalEditShift, setModalEditShift] = useState(false);
  const [showModalEditAssign, setShowModalEditAssign] = useState(false);
  const [modalDelete, setShowModalDelete] = useState(false);

  // SHIFT DETAIL
  const [shiftDetailId, setShiftDetailID] = useState();
  const [shiftDetailData, setShiftDetailData] = useState();
  const [shiftNameAssign, setShiftNameAssign] = useState([]);
  const [valueShiftName, setValueShiftName] = useState();

  // ASSIGN SHIFT
  const [uIdAssignShiftDetail, setUIdAssignShiftDetail] = useState();
  const [dataAssign, setAssignData] = useState({});
  const [editAssignShift, setEditAssignShift] = useState([]);
  const [confirmDeleteAssignShift, setConfirmDeleteAssignshift] =
    useState(false);
  const [assignSelectJob, setAssignJob] = useState([]);

  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  // SHIFT REPLACEMENT
  const [titleReplacement, setTitleReplacement] = useState(false);
  // SHOW REPLACEMENT

  const { dataDate, dateRange, selectView, locationState } = useSelector(
    (state) => state.employeeView
  );

  const { currentRole } = useSelector((state) => state.loginSlice);

  const startDate = dataDate.length > 0 && dataDate[0];
  const endDate = dataDate.length > 0 && dataDate[dataDate.length - 1];

  const { showRequestReplacement } = useSelector(
    (state) => state.requestReplacement
  );

  const { remainingHeight, submitName } = useDivRef();

  // ALL CONTEXT FROM REPLACEMENT CONFLICK
  const { handleConflickData, modalConflickData, onCloseModalConflick } =
    useReplacementConflick();

  // FILTER DATA BY SHIFTNAME
  const filterByShiftName =
    dataAssign.shift &&
    dataAssign.shift.length > 0 &&
    dataAssign.shift.filter((item) => {
      return valueShiftName && valueShiftName === item.uId;
    });

  // HANDLE SHOW SHIFT DETAILS
  const handleShowShiftDetails = (data, idx) => {
    setShiftDetailID(idx);

    setShiftDetailData(data);

    setModalEditShift(true);
  };

  // HANDLE SHOW ASSIGN SHIFT
  const handleShowAssign = (data, idx) => {
    setAssignData(data);
    // setAssignID(idx);

    // JOB POSITION FROM EMPLOYEE INCLUDE ON SHIFT
    const jobPositionSelect =
      data &&
      data.shift.length > 0 &&
      data.shift.flatMap((select) => {
        return (
          select.employees.length > 0 &&
          select.employees.map((selectJob) => {
            return {
              label: selectJob.jobPosition,
              value: selectJob.jobPosition,
            };
          })
        );
      });

    setAssignJob(jobPositionSelect ? jobPositionSelect : []);

    // shift name input select
    const shiftName =
      data &&
      data.shift &&
      data.shift.map((item) => {
        return {
          label: item.shiftName,
          value: item.uId,
        };
      });

    setShiftNameAssign(shiftName);

    setShowModalAssign(true);
  };

  // GET SHIFT DETAIL
  const { data: dataShiftTemplate, isLoading: loadingGetAllshift } =
    useGetAllShift({
      onSuccess: () => {
        // queryClient.invalidateQueries("getAllShift");
      },
      onError: (err) => {
        console.log("something wrong", err);
      },
    });

  // UPDATE SHIFT DETAIL
  const { mutate: updateShiftDetail, isLoading: loadingUpdateShift } =
    useUpdateShift(shiftDetailId, {
      onSuccess: () => {
        queryClient.invalidateQueries("getAllShift");
        alertSuccess("Data Success Updated");
        setModalEditShift(false);
      },
      onError: (err) => {
        alertError("Something Wrong");
        console.log("something wrong", err);
      },
    });

  // DELETE SHIFT DETAIL
  const { mutate: deleteShiftDetail, isLoading: loadingDeleteShift } =
    useDeleteShift(shiftDetailId, {
      onSuccess: () => {
        queryClient.invalidateQueries("getAllShift");
        alertSuccess("Data has been Deleted");
        setShowModalDelete(false);
        setModalEditShift(false);
      },
      onError: (err) => {
        alertError("Something Wrong!");
        console.log("something wrong", err);
      },
    });

  const onConfirmDeleteShift = () => {
    deleteShiftDetail();
  };

  // GET ALL ASSIGN SHIFT
  const {
    data: dataDashboard,
    refetch,
    isSuccess,
  } = useGetAllDashboard({
    fromDate: dateRange.fromDate.toISOString(),
    toDate: dateRange.toDate.toISOString(),
  });

  // GET ALL LOCATION
  const {
    data: locationData,
    isLoading: loadingLocation,
    isSuccess: successLocation,
  } = useGetAllLocation();

  // ALL EMPLOYEE
  const { data: dataEmployees, isLoading: loadingEmployee } = useGetAllEmployee(
    {
      firstName: submitName,
      startDate: formatDate(startDate),
      endDate: formatDate(endDate),
      locationId: locationState,
    }
  );

  useEffect(() => {
    // if (isSuccess && dataDashboard) {
    //   refetch();
    // }

    if (locationData?.data?.length > 0 && !locationState) {
      dispatch(setLocationState(locationData.data[0].uId)); // Set default value ke Redux
    }
  }, [
    dateRange,
    isSuccess,
    dataEmployees,
    dataShiftTemplate,
    dataDashboard,
    shiftNameAssign,
    // locationData,
    // locationState,
  ]);

  // DELETE ASSIGN SHIFT
  const { mutate: deleteAssignShift, isLoading: loadingDeleteAssignShift } =
    useDeleteAssignShift({
      uId: uIdAssignShiftDetail,
      onSuccess: () => {
        alertSuccess("Data Assign Shift has been Deleted");
        queryClient.invalidateQueries("getDashboard");
        setConfirmDeleteAssignshift(false);
        setShowModalEditAssign(false);
      },
      onError: (err) => {
        alertError("Something Wrong!");
        console.log("error : ", err);
      },
    });

  if (
    loadingGetAllshift ||
    loadingUpdateShift ||
    loadingDeleteShift ||
    loadingLocation ||
    loadingDeleteAssignShift ||
    loadingEmployee
  ) {
    return null;
  }

  // SELECT LOCATION
  const selectLocation =
    locationData?.data?.length > 0 &&
    locationData?.data?.map((item) => {
      return {
        label: item.locationName,
        value: item.uId,
      };
    });

  // SELECT FOR LOCATION ON THE SHIFT LIST BOX

  // HANDLE EDIT ASSIGN SHIFT
  const handleEditAssignShift = (data, idx) => {
    setUIdAssignShiftDetail(idx);
    setEditAssignShift(data);

    setShiftNameAssign(data);

    setShowModalEditAssign(true);
  };

  const filterByNonAdmin =
    dataEmployees.data.length > 0 &&
    dataEmployees.data.map((item) => {
      const filterEmp = item.employee.filter((employee) => {
        return !employee.role.includes("Admin");
      });

      return {
        ...item,
        employee: filterEmp,
      };
    });

  const filterAdmin =
    filterByNonAdmin.length > 0 &&
    filterByNonAdmin.filter((item, index) => {
      return item.employee.length !== 0;
    });

  // dispatch(setLocationState(locationSelect));
  // dispatch(setLocationState(locationData?.data[0]?.uId));
  // || locationData?.data[0]?.uId

  return (
    <div className="w-full">
      <div className="w-full">
        <ShiftListBox
          locationData={selectLocation}
          // FROM REDUX
          locationState={locationState}
          // locationState={locationData?.data[0]?.uId}
          // ONCHANGE FILTER LOCATION
          onChangeLocation={(e) => {
            // setSelectLocation(e.target.value);
            dispatch(setLocationState(e.target.value));
          }}
          selectView={selectView}
          dataDate={dataDate}
          shiftDataTemplate={dataShiftTemplate?.data}
          handleClickFormShift={handleShowShiftDetails}
          handleShowAssign={handleShowAssign}
          dataEmployees={filterAdmin}
          dataAssignShift={dataDashboard}
          handleEditAssignShift={handleEditAssignShift}
          // FROM CONTEXT
          handleConflickAssign={handleConflickData}
          // ROLE USER
          roleUser={currentRole === "Admin"}
          divHeightEmployee={remainingHeight}
        />
      </div>

      {/* MODAL ADD ASSIGN SHIFT */}
      {showModalAssign && (
        <ModalDialog
          onClose={() => {
            setShowModalAssign(false);
            setShiftNameAssign([]);
          }}
          title={"Assign Shift"}
          className={"h-[700px] w-[750px] "}
        >
          <AddAssignShift
            shiftNameAssign={shiftNameAssign}
            setValueShiftName={setValueShiftName}
            filterByShiftName={filterByShiftName}
            dataEmployee={filterAdmin}
            dataAssign={dataAssign}
            selectJobs={assignSelectJob}
            setShowModalAssign={setShowModalAssign}
          />
        </ModalDialog>
      )}

      {/* MODAL EDIT ASSIGN SHIFT */}
      {showModalEditAssign && !showRequestReplacement && (
        <ModalEditAssignShift
          dataEmployees={filterAdmin}
          setConfirmDeleteAssignshift={setConfirmDeleteAssignshift}
          setTitleReplacement={setTitleReplacement}
          showRequestReplacement={showRequestReplacement}
          titleReplacement={titleReplacement}
          setShowModalEditAssign={setShowModalEditAssign}
          currentRole={currentRole}
          shiftNameAssign={shiftNameAssign}
          setValueShiftName={setValueShiftName}
          editAssignShift={editAssignShift}
          assignSelectJob={assignSelectJob}
          uIdAssignShiftDetail={uIdAssignShiftDetail}
        />
      )}

      {/* SHOW MODAL FIND REPLACEMENT */}
      {showRequestReplacement && (
        <ModalDialog
          title={"Find Replacement"}
          onClose={() => {
            dispatch(setShowRequesReplacement(false));
          }}
          className={"h-[700px] w-[750px] "}
        >
          <FindReplacementRequest
            shiftData={editAssignShift}
            uIdShift={uIdAssignShiftDetail}
            setShowModalEditAssign={setShowModalEditAssign}
          />
        </ModalDialog>
      )}

      {/* MODAL EDIT SHIFT DETAIL */}
      {showModalEditShift && (
        <ModalEditShiftDetail
          currentRole={currentRole}
          setModalEditShift={setModalEditShift}
          setShowModalDelete={setShowModalDelete}
          shiftDetailData={shiftDetailData}
          updateShiftDetail={updateShiftDetail}
        />
      )}

      {/* MODAL DELETE ASSIGN SHIFT DETAIL */}
      {confirmDeleteAssignShift && (
        <ModalConfirmation
          title={"Delete One Assign Shift Detail"}
          headMessage={"This is will be Delete one Assign Shift !"}
          onClose={() => {
            setConfirmDeleteAssignshift(false);
          }}
          onConfirm={() => {
            deleteAssignShift(editAssignShift.toFormValue);
          }}
        />
      )}

      {/* MODAL DELETE SHIFT DETAIL */}
      {modalDelete && (
        <ModalConfirmation
          title={"Delete One Shift"}
          headMessage={"This is will be Delete one Shift !"}
          onClose={() => {
            setShowModalDelete(false);
          }}
          onConfirm={onConfirmDeleteShift}
        />
      )}

      {/* REPLACEMENT CONFLICK DATA */}
      {modalConflickData && (
        <ModalDialog
          title={"Conflict"}
          className={"w-[730px] h-[700px] "}
          onClose={onCloseModalConflick}
        >
          <ConflictOneData />
        </ModalDialog>
      )}
    </div>
  );
}

export default EmployeeView;

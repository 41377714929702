import React, { useState, useEffect } from "react";
import {
  InputSelect,
  SearchBoxV2,
  InputDateRange,
  // InputDateWeekPickerRange,
} from "@bluesilodev/timhutcomponents";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setDataDate } from "store/employeeViewSlice";
// import { useExceedingTime } from "services/exceedingTimeAttendance";

import {
  getCurrentWeekDates,
  formatDate,
} from "utils/common/generateDateRange";
import { useGetAllEmployee } from "services/employeeApi";
import { setSelectView } from "store/employeeViewSlice";

import ButtonAllEmployeeView from "./allButtonEmployeeView";
import { useDivRef } from "context/refProvider";
import InputDateWeekPickerRange from "components/allInput/InputDateWeekPickerRange";

function AllButton({
  setFieldValue,
  values,
  showConfirmPublish,
  setDateValue,
}) {
  const [searchEmployee, setSearchEmployee] = useState();
  const [submitSearch, setSubmitSearch] = useState("");
  const startDate = values.date.length > 0 && values.date[0];
  const endDate = values.date.length > 0 && values.date[values.date.length - 1];
  const { setSubmitName } = useDivRef();

  const [selectViewState, setSelectViewState] = useState("Employee View");

  const navigate = useNavigate();
  const dispatch = useDispatch(selectViewState);

  useEffect(() => {
    if (selectViewState === "Employee View") {
      navigate("/");
    }
    // else if (selectViewState === "Job Position View") {
    //   navigate("/jobPositionView");
    // }
  }, [selectViewState, navigate]);

  // CHANGE TO ROLE VIEW OR EMPLOYEE VIEW
  const handleChangePage = (e) => {
    if (e.target.value === "Employee View") {
      setSelectViewState("Employee View");

      navigate("/");
    } else if (e.target.value === "Job Position View") {
      // navigate("/jobPositionView");
      setSelectViewState("Job Position View");
    }
  };

  // BUTTON TODAY
  const handleTodayDate = () => {
    const weekDates = getCurrentWeekDates();

    dispatch(setDataDate(weekDates));
  };

  // const { data: exceeedingData, isLoading: loadingExceed } = useExceedingTime(
  //   {}
  // );

  // GET ALL EMPLOYEE
  const {
    data: dataEmployees,
    isLoading: loadingEmployee,
    refetch,
    isSuccess,
  } = useGetAllEmployee({
    firstName: submitSearch,
    startDate: formatDate(startDate),
    endDate: formatDate(endDate),
  });

  // FETCH DATA IF SUCCES GET API
  if (isSuccess && dataEmployees.data.length > 0) {
    refetch();
  }

  if (loadingEmployee) {
    return <div>Loading... </div>;
  }

  // FILTERING EMPLOYEE BY ROLE ADMIN AND REPORTO

  // ADD EXCEEDING TIME

  dispatch(setSelectView(selectViewState));

  return (
    <div className="w-full flex justify-between mt-3">
      <div className="flex gap-3">
        <InputSelect
          value={selectViewState}
          // disabled={true}
          title={"View"}
          classname={"w-[200px]"}
          options={[
            {
              label: "Employee View",
              value: "Employee View",
            },
            {
              label: "Job Position View",
              value: "Job Position View",
            },
          ]}
          onChange={handleChangePage}
        />

        {/* <InputDateRange
          name={"date"}
          value={values.date}
          setFieldValue={setFieldValue}
          // startDateDefault={initialStartDate}
          // endDateDefault={initialEndDate}
        /> */}

        <InputDateWeekPickerRange
          name={"date"}
          setFieldValue={setFieldValue}
          setFetchDataDateRange={setDateValue}
        />

        <button onClick={handleTodayDate}>
          <span className="w-[80px] h-[60px] bg-gray-100 flex justify-center items-center rounded-md ">
            <h1 className="text-sm">Today</h1>
          </span>
        </button>

        <SearchBoxV2
          value={searchEmployee}
          onChange={(e) => {
            setSearchEmployee(e.target.value);
          }}
          submitSearch={() => {
            setSubmitName(searchEmployee);
            setSubmitSearch(searchEmployee);
            setSearchEmployee("");
          }}
          className={"h-[60px] w-full"}
          placeholder={"Search Employee"}
        />

        {/* {selectViewState === "Job Position View" && (
          <SearchBoxV2
            // value={searchEmployee}
            // onChange={(e) => {
            //   setSearchEmployee(e.target.value);
            // }}
            // submitSearch={() => {
            //   setSubmitSearch(searchEmployee);
            //   setSearchEmployee("");
            // }}
            className={"h-[60px] w-full"}
            placeholder={"Search Employee"}
          />
        )} */}
      </div>

      <ButtonAllEmployeeView
        values={values}
        showConfirmPublish={showConfirmPublish}
      />

      {/* {selectViewState === "Job Position View" && (
        <ButtonAllJobView values={values} />
      )} */}
    </div>
  );
}

export default AllButton;

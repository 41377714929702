import React, { useState, useContext, useEffect } from "react";
import {
  useGetHistoryByIdReplacement,
  useGetHistoryReplacement,
} from "services/conflickDataApi";
import ApproveData from "components/approveData";
import DetailsShiftReplacement from "./detailShiftReplacement";

function ShiftReplacementAll() {
  const [replacementById, setReplacementById] = useState("");
  const [showModalReplace, setShowModalReplace] = useState(false);

  const handleIsApproveRequest = (uId) => {
    setReplacementById(uId);
    setShowModalReplace(true);
  };

  const { data: historyReplacement, isLoading } = useGetHistoryReplacement();

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      {historyReplacement.data.length > 0 &&
        historyReplacement.data.map((item, index) => {
          const previousUserData = item.previousUserData;
          const replacementUserData = item.replacementUserData;
          const shiftData = item.shiftData;
          const assignShiftData = item.assignShiftData;
          const status = item.status;

          const createBy = item.createdByData;

          return (
            <ApproveData
              key={index}
              dateRequest={item.createdAt}
              assignShiftDate={assignShiftData?.date}
              shiftName={shiftData?.shiftName}
              locationName={previousUserData.locationName}
              jobPosition={assignShiftData?.jobPosition}
              startTime={shiftData?.startTime}
              endTime={shiftData?.endTime}
              employeeName={previousUserData.firstName}
              requestorPhoto={previousUserData.photo}
              replacementPhoto={replacementUserData?.photo}
              nameReplacement={replacementUserData.firstName}
              statusAproveReplacement={status.byEmployee.status}
              statusClick={true}
              handleApproveRequest={() => handleIsApproveRequest(item.uId)}
              adminId={createBy.uId}
              hrAdminName={createBy.firstName}
              hrAdminPhoto={createBy.photo}
              statusAproveHr={status.byAdmin.status}
            />
          );
        })}

      {showModalReplace && (
        <div>
          <DetailsShiftReplacement
            replacementById={replacementById}
            setShowModalReplace={setShowModalReplace}
          />
        </div>
      )}
    </div>
  );
}

export default ShiftReplacementAll;

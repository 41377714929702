import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  ClockIcon,
  ChevronUpIcon,
  ChevronDownIcon,
} from "@heroicons/react/24/outline";

const InputTime = ({
  label,
  name,
  value,
  disable,
  required,
  setFieldValue,
  error,
  ...props
}) => {
  const ref = React.useRef(null);
  const [active, setActive] = useState(false);
  const [hours, setHours] = useState(parseInt(value.split(":")[0], 10) || 0);
  const [minutes, setMinutes] = useState(
    parseInt(value.split(":")[1], 10) || 0
  );

  useEffect(() => {
    setHours(parseInt(value.split(":")[0], 10) || 0);
    setMinutes(parseInt(value.split(":")[1], 10) || 0);
  }, [value]);

  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        setActive(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const newTime = `${checkHours()}:${checkMinutes()}`;
    setFieldValue(name, newTime);
  }, [hours, minutes]);

  const checkHours = () => {
    return hours < 10 ? `0${hours}` : hours;
  };

  const checkMinutes = () => {
    return minutes < 10 ? `0${minutes}` : minutes;
  };

  const isDisable = () => {
    return disable ? "bg-[#F2F4F5] border-none" : "";
  };

  const handleAddHour = () => {
    setHours((prevHours) => (prevHours >= 23 ? 0 : prevHours + 1));
  };

  const handleMinusHour = () => {
    setHours((prevHours) => (prevHours <= 0 ? 23 : prevHours - 1));
  };

  const handleAddMinute = () => {
    setMinutes((prevMinutes) => (prevMinutes >= 59 ? 0 : prevMinutes + 1));
  };

  const handleMinusMinute = () => {
    setMinutes((prevMinutes) => (prevMinutes <= 0 ? 59 : prevMinutes - 1));
  };

  return (
    <div ref={ref} className={`w-full relative`}>
      <div
        className={`border-[1px] border-black h-[60px] rounded-md flex items-center justify-between w-full cursor-pointer ${isDisable()}`}
        onClick={() => {
          if (!disable) {
            setActive(!active);
          }
        }}
      >
        <div className="flex flex-row justify-between w-full px-3">
          <div className="flex flex-col">
            <label className="input-label flex flex-row text-[14px]">
              {label} <p>{required ? <div className="ml-1">*</div> : ""}</p>
            </label>
            <div className="flex">
              <input
                id={name}
                className={`outline-none mx-1 w-[2ch] cursor-pointer ${isDisable()}`}
                value={`${checkHours()}`}
                disabled={isDisable()}
                onChange={(e) => {
                  const hours = parseInt(e.target.value) || 0;
                  setHours(hours >= 24 ? 0 : hours);
                }}
                {...props}
              />
              {":"}
              <input
                id={name}
                className={`outline-none mx-1 w-[2ch] cursor-pointer ${isDisable()}`}
                value={`${checkMinutes()}`}
                disabled={isDisable()}
                onChange={(e) => {
                  const minutes = parseInt(e.target.value) || 0;
                  setMinutes(minutes >= 60 ? 0 : minutes);
                }}
                {...props}
              />
            </div>
          </div>
          <button
            type="button"
            disabled={isDisable()}
            onClick={() => setActive(!active)}
          >
            <ClockIcon className="w-7 h-7" />
          </button>
        </div>
      </div>
      {active && !disable && (
        <div className="time-container bg-white shadow-lg rounded-lg p-1 absolute z-20">
          <div className="flex flex-row">
            <div className="flex flex-col">
              <button type="button" className="w-full" onClick={handleAddHour}>
                <ChevronUpIcon className="hover:stroke-sky-300 stroke-gray-700 w-14 h-8" />
              </button>
              <h1 className="text-5xl text-gray-700">{checkHours()}</h1>
              <button
                className="w-full"
                type="button"
                onClick={handleMinusHour}
              >
                <ChevronDownIcon className="hover:stroke-sky-300 stroke-gray-700 w-14 h-8" />
              </button>
            </div>

            <div className="text-gray-700 text-5xl mt-7">:</div>

            <div className="flex flex-col">
              <button
                className="w-full"
                type="button"
                onClick={handleAddMinute}
              >
                <ChevronUpIcon className="hover:stroke-sky-300 stroke-gray-700 w-14 h-8" />
              </button>
              <h1 className="text-5xl text-gray-700">{checkMinutes()}</h1>
              <button
                className="w-full"
                type="button"
                onClick={handleMinusMinute}
              >
                <ChevronDownIcon className="hover:stroke-sky-300 stroke-gray-700 w-14 h-8" />
              </button>
            </div>

            <button
              type="button"
              className="text-black text-lg rounded-full hover:text-sky-300 my-10 mx-5"
              onClick={() => setActive(!active)}
            >
              OK
            </button>
          </div>
        </div>
      )}

      {error && !active && <div className="text-red-500 ">{error}</div>}
    </div>
  );
};

InputTime.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.string,
  disable: PropTypes.bool,
  required: PropTypes.bool,
  setFieldValue: PropTypes.func,
  error: PropTypes.any,
};

InputTime.defaultProps = {
  label: "Start Time",
  name: "time",
  value: "00:00",
  disable: false,
  required: true,
  setFieldValue: () => {},
  error: null,
};

export default InputTime;

import React, { useEffect, useState } from "react";
import {
  ModalDialogV2,
  InputSelect,
  InputDate,
  Button,
} from "@bluesilodev/timhutcomponents";

import { formatDateV2 } from "utils/common/generateDateRange";
import { generateFilterBySelect } from "utils/common/generatedTime";
import { useDownloadExcel } from "services/shiftServices";

import { useGetAllLocation, useGetAllJobPosition } from "services/employeeApi";
import { useGetAllEmployeeAssigned } from "services/assignShiftService";

function ModalDownloadExcel({ setShowModalDowload }) {
  const [selectFilterBy, setSelectFilterBy] = useState("Today");
  const [selectLocation, setSelectLocation] = useState();
  const [selectJobPosition, setSelectJobPosition] = useState();
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [selectEmployee, setSelectEmployee] = useState();

  const [dataAssignedEmployee, setDataAssignedEmployee] = useState([]);

  const onCLoseModal = () => {
    setShowModalDowload(false);
  };

  const { mutateAsync: mutateData, isLoading: loadingPostExcel } =
    useDownloadExcel({
      onSuccess: () => {},
      onError: (err) => {
        console.log("Something Wrong !", err);
      },
    });

  const { data: locationData, isLoading: locationLoading } =
    useGetAllLocation();

  const { data: allJobPosition, isLoading: loadingJobPosition } =
    useGetAllJobPosition();

  const { data: employeeAssigned, isSuccess } = useGetAllEmployeeAssigned({
    customfromDate:
      startDate && selectFilterBy === "Custom Date" && formatDateV2(startDate),
    customtoDate:
      endDate && selectFilterBy === "Custom Date" && formatDateV2(endDate),
    selectFilterBy: selectFilterBy,
    location: selectLocation,
    jobPosition: selectJobPosition,
  });

  useEffect(() => {
    if (isSuccess && employeeAssigned) {
      setDataAssignedEmployee(employeeAssigned.data);
    }
  }, [
    employeeAssigned,
    isSuccess,
    selectFilterBy,
    startDate,
    endDate,
    selectLocation,
    selectJobPosition,
    dataAssignedEmployee,
  ]);

  if (locationLoading || loadingJobPosition || loadingPostExcel) {
    return null;
  }

  const allLocation =
    locationData.data.length > 0 &&
    locationData.data.map((item) => {
      return {
        label: item.locationName,
        value: item.locationName,
      };
    });

  const listAllJobPosition =
    allJobPosition?.data.length > 0 &&
    allJobPosition?.data.map((item) => {
      return {
        label: item.jobPosition,
        value: item.jobPosition,
      };
    });

  const exportToExcel = async () => {
    const selectTime = generateFilterBySelect(selectFilterBy);

    // const dataFromRedux = dataExcel.payload;
    const body = {
      startTime:
        (selectFilterBy && selectTime?.startTime) ||
        (startDate && formatDateV2(startDate)),
      endTime:
        (selectFilterBy && selectTime?.endTime) ||
        (endDate && formatDateV2(endDate)),
      location: selectLocation,
      jobPosition: selectJobPosition,
      employeeId: selectEmployee,
    };

    try {
      // send data to server
      const responseData = await mutateData(body);

      const url = window.URL.createObjectURL(responseData);
      const a = document.createElement("a");
      a.href = url;
      a.download = "Timhut_Scheduling.xlsx";
      document.body.appendChild(a);
      a?.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);

      // // ask to user for choice location directory
      // const fileHandle = await window.showSaveFilePicker({
      //   suggestedName: "comunity.xlsx",
      //   types: [
      //     {
      //       description: "Excel files",
      //       accept: {
      //         "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
      //           [".xlsx"],
      //       },
      //     },
      //   ],
      // });

      // // // write data to file you choice
      // const writable = await fileHandle.createWritable();

      // await writable.write(responseData); // write blob to the file
      // await writable.close();
    } catch (error) {
      console.log("Something Wrong : ", error);
      return;
    }
  };

  return (
    <ModalDialogV2
      title={"Export"}
      onClose={onCLoseModal}
      className={" w-[700px] flex flex-col gap-4"}
    >
      <div className="h-[300px]  flex flex-col gap-6">
        <div className="flex w-full gap-3 ">
          <div
            className={`${
              selectFilterBy !== "Custom Date" ? "w-[32%] " : "w-full"
            }`}
          >
            <InputSelect
              options={[
                {
                  label: "Today",
                  value: "Today",
                },
                {
                  label: "Past Week",
                  value: "Past Week",
                },
                {
                  label: "Past 1 Month",
                  value: "Past 1 Month",
                },
                {
                  label: "Past 3 Month",
                  value: "Past 3 Month",
                },
                {
                  label: "Custom Date",
                  value: "Custom Date",
                },
              ]}
              title={"Filter by"}
              value={selectFilterBy}
              onChange={(e) => setSelectFilterBy(e.target.value)}
            />
          </div>

          {selectFilterBy === "Custom Date" && (
            <>
              <InputDate
                name={"startDate"}
                label={"Start Date"}
                positionCalendar={"right-0"}
                value={startDate}
                // onBlur={handleBlur}
                setFieldValue={(name, val) => {
                  setStartDate(val);
                }}
              />

              <InputDate
                name={"endDate"}
                label={"endDate"}
                positionCalendar={"right-0"}
                value={endDate}
                // onBlur={handleBlur}
                setFieldValue={(name, val) => {
                  setEndDate(val);
                }}
              />
            </>
          )}
        </div>

        <div className="flex w-full gap-3 ">
          <InputSelect
            options={allLocation}
            title={"Locations"}
            value={selectLocation}
            defaultValue={{ label: "All Locations", value: "" }}
            onChange={(e) => setSelectLocation(e.target.value)}
          />
          <InputSelect
            options={listAllJobPosition}
            title={"Job Positions"}
            value={selectJobPosition}
            defaultValue={{ label: "All Job Positions", value: "" }}
            onChange={(e) => setSelectJobPosition(e.target.value)}
          />
          <InputSelect
            options={
              dataAssignedEmployee?.length > 0 ? dataAssignedEmployee : [{}]
            }
            title={"Employee"}
            value={selectEmployee}
            defaultValue={{ label: "All Employees", value: "" }}
            onChange={(e) => setSelectEmployee(e.target.value)}
          />
        </div>

        <Button
          type={"button"}
          disabled={!dataAssignedEmployee}
          onClick={exportToExcel}
          style={dataAssignedEmployee ? "solid" : "secondary"}
          className={"w-full group group-hover:text-white"}
          label={
            <div className="flex items-center gap-2">
              {/* <MagicSvg
                className={"group-hover:text-white"}
                color={"orange-500"}
              /> */}
              <h1>Export to csv</h1>
            </div>
          }
        />
      </div>
    </ModalDialogV2>
  );
}

export default ModalDownloadExcel;
